<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                Notification Config
                <small>Admin Options</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="sn"
            hide-actions
            :headers="headers"
            :items="noticeOption"
            :search="search"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td>{{ props.item.title }}</td>
                <td>{{ props.item.use_Notice }}</td>
                <td>{{ props.item.newOpenService }}</td>
                <td>{{ props.item.updateService }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>
    </div>

    <j-form-modal
      title="Notification"
      ref="formHandler"
      @create="add()"
      @edit="edit()"
      @delete="del()"
      @cancel="cancel()"
      :formMode="formode"
      :resetable="resetable()"
      :opened.sync="modOpen"
      :modalType="'type02'"
      class="type01"
    >
      <v-container>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model="selectedItem.title"
              label="Notification"
              placeholder="Input/Edit Notification"
            ></v-text-field>
          </v-flex>
            <v-flex xs12 sm6>
              <j-radio-group
                v-model="selectedItem.use_Notice"
                label="Use Notification"
                name="UseNotice"
                :checked="'Y'"
                :options="[
                  { text: 'Yes', value: 'Y'},
                  { text: 'No', value: 'N'},
                ]"
              />
            </v-flex>
          <v-flex xs12 class="wrap__multiselect">
            <v-select
              v-model="newOpenService"
              multiple
              return-object
              item-text="name"
              label="New Open Service"
              placeholder="Select to Navation Menu"
              :items="newOpenServiceOptions"
              :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
            ></v-select>
          </v-flex>
          <!-- <v-flex xs12 class="wrap__multiselect">
            <v-select
              v-model="updateService"
              multiple
              return-object
              item-text="name"
              label="Update Service"
              placeholder="Select to DataSource"
              :items="updateServiceOptions"
              :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
            ></v-select>
          </v-flex>           -->
        </v-layout>
      </v-container>
    </j-form-modal>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { AdminService, SystemCodeService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"

export default {
  name: "svccontrol-user-account",
  components: {
    JFormModal
  },
  data: () => ({
    adminService: null,
    systemCodeService: null,
    modOpen: false,
    picPopoverShow: false,
    deptOptions: [],
    newOpenService: [],
    newOpenServiceOptions: [],
    updateService: [],
    updateServiceOptions: [],
    userPermit: [],
    search: "",
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      {
        type: "text",
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
        width: 120
      },
      {
        type: "text",
        text: "Use Notification",
        align: "left",
        sortable: true,
        value: "use_Notice",
        width: 100
      },
      {
        type: "text",
        text: "New Open Service",
        align: "left",
        sortable: true,
        value: "newOpenService",
        width: 200
      },
      {
        type: "text",
        text: "Update Service",
        align: "left",
        sortable: true,
        value: "updateService",
        width: 200
      },      
    ],
    noticeOption: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { }
  }),
  watch: {
    newOpenService(val) {
      let newOpenSer = []
      val.forEach(d => {
        newOpenSer.push(d)
      })
      this.selectedItem.newOpenService = newOpenSer.join(",")
    },
    updateService(val) {
      let updateSer = []
      val.forEach(d => {
        updateSer.push(d)
      })
      this.selectedItem.updateService = updateSer.join(",")
    },
  },
  created() {
    this.adminService = new AdminService()
    this.systemCodeService = new SystemCodeService()
   
  },
  computed: {

  },
  mounted() {
    this.systemCodeService.getNavList(res => {
      let menu = []
      if (res && res.length > 0) {
        res.forEach(d => {
          let names =  d.subitem 
          ? 'PHASE : '+d.phase + '   /      ITEM : ' + d.item + '   /      SUBITEM : ' + d.subitem 
          : 'PHASE : '+d.phase + '   /      ITEM : ' + d.item 
          menu.push(names)
        })
        this.newOpenServiceOptions = [...menu]
      }
    })
    this.systemCodeService.getDataSourceList(res => {
      let menu = []
      if (res && res.length > 0) {
        res.forEach(d => {
          let names = 'Datasource : ' +d.title +'  ' +d.revision+'   '+d.week_no
          menu.push(names)
        })
      }
      this.updateServiceOptions = [...menu]
    })

    this.systemCodeService.getNotification(res => {
      if (res && res.length > 0) {
        this.setnoticeOption(res)
      } else {
         this.noticeOption = []
      }
    })
  },
  methods: {
    setnoticeOption(res) {
      if (!res) res = [];
      this.noticeOption = res;
      this.modOpen = false;
    },
    getNotice() {
      this.systemCodeService.getNotification(res => {
        if (res && res.length > 0) {
          this.setnoticeOption(res)
        } else {
          this.noticeOption = []
        }
      })
    },
    add() {

      this.yes = () => {
        this.msgOpen = false;
        let params = new URLSearchParams()
        params.append('title', this.selectedItem.title)        
        params.append('use_Notice', this.selectedItem.use_Notice)
        params.append('newOpenService', this.selectedItem.newOpenService)
        params.append('updateService', this.selectedItem.updateService)

        this.systemCodeService.putNotification(params, res => {
          this.getNotice()
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to add new Notification?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgInfo.button = [true, false, true];
      this.msgOpen = true;
      
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;
        let params = new URLSearchParams()
        params.append('title', this.selectedItem.title)        
        params.append('use_Notice', this.selectedItem.use_Notice)
        params.append('newOpenService', this.selectedItem.newOpenService)
        params.append('updateService', this.selectedItem.updateService)
        params.append('before_title', this.selectedPrevItem[0].title)         
        this.systemCodeService.upNotification(params, res => {
          this.getNotice()
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        let params = new URLSearchParams()
        params.append('title', this.selectedItem.title)        
        params.append('use_Notice', this.selectedItem.use_Notice)
        params.append('newOpenService', this.selectedItem.newOpenService)
        params.append('updateService', this.selectedItem.updateService)         
        this.systemCodeService.delNotification(params, res => {
          this.getNotice()
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      this.msgInfo.message = "Do you want to delete selected Menu Notification?";
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    propStatus(props) {
      this.selectedIndex = this.noticeOption.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].id === props.item.id) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      // this.userPermit = this.selectedItem.userPermit.split(',')
      this.modOpen = true;
      this.newOpenService = [];
      this.selectedItem.newOpenService.split(",").forEach(code => {
         this.newOpenService.push(code)
      })

      this.updateService = [];
      this.selectedItem.updateService.split(",").forEach(code => {
         this.updateService.push(code)
      })

    },
    setAdd() {
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.$refs.formHandler.formReset();
      this.selectedItem.updateService = this.updateServiceOptions.join(",")      
      this.modOpen = true;
    },
    setEdit(selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.navItem[this.selectedIndex]);
      this.selected = [Object.assign({}, this.navItem[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          !!this.selectedItem.title ||
          !!this.selectedItem.use_Notice ||
          !!this.selectedItem.newOpenService ||
          !!this.selectedItem.updateService 
        )
          return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].title != this.selectedItem.title ||
          this.selectedPrevItem[0].use_Notice != this.selectedItem.use_Notice ||
          this.selectedPrevItem[0].newOpenService != this.selectedItem.newOpenService ||
          this.selectedPrevItem[0].updateService != this.selectedItem.updateService 
        )
          return true;
        return false;
      }
    },
  }
};
</script>
